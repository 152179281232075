import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { phoneLastFour } from 'utils/helpers/sanitation';

import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  LoadingOverlay,
} from '@frontend/common';


import {
  getTwoFactorToken,
} from '../../actions';

import { REGISTER_METHODS } from '../../constants';

import styles from '../../styles.module.css';

const msg_send_security_code = (fourDigits) => `Send a security code to (***) ***-${fourDigits}`;

export class PhoneMethods extends Component {

  static propTypes = {
    preferredMethod: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    onSubmitPhoneSuccess: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    selectedMethod: this.props.preferredMethod,
    phoneNumber: '',
    selectedMethodError: '',
  };

  submitPhoneMethod() {
    const { getTwoFactorToken, onSubmitPhoneSuccess } = this.props;
    const { selectedMethod } = this.state;

    if (selectedMethod === REGISTER_METHODS.SMS || selectedMethod === REGISTER_METHODS.CALL) {
      this.setState({ isLoading: true });
      getTwoFactorToken(selectedMethod)
        .then(() => {
          this.setState({ isLoading: false });
          onSubmitPhoneSuccess(selectedMethod);
        });
    }
    else {
      this.setState({ selectedMethodError: 'Please select an option.' });
    }
  }

  render() {
    const { phoneNumber } = this.props;
    const { selectedMethod, selectedMethodError, isLoading } = this.state;

    return (
      <div>
        <h3>{msg_send_security_code(phoneLastFour(phoneNumber))}</h3>
        <div className={styles.methods}>
          <RadioGroup aria-label='registration method' name='method' value={selectedMethod} onChange={(e) => this.setState({ selectedMethod: e.target.value })}>
            <FormControlLabel value={REGISTER_METHODS.SMS} control={<Radio />} label='Text Me' />
            <FormControlLabel value={REGISTER_METHODS.CALL} control={<Radio />} label='Call Me' style={{ marginTop: '5px' }} />
          </RadioGroup>
          <FormHelperText error={Boolean(selectedMethodError)}>{selectedMethodError}</FormHelperText>
        </div>

        <LoadingOverlay show={isLoading}>
          <Button
            variant='contained'
            style={{ marginTop: '15px' }}
            className={styles.buttons}
            onClick={() => this.submitPhoneMethod()}
          >
            Request code
          </Button>
        </LoadingOverlay>
      </div>
    );
  }
}

export default connect(null, {
  getTwoFactorToken,
})(PhoneMethods);