import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from '@mui/material';

import { LoadingOverlay } from '@frontend/common';

import { resetClaims } from 'components/AppRoot/Navigation/actions';

import styles from '../../styles.module.css';

export class Confirmation extends Component {

  static propTypes = {
    resetClaims: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
  };

  resetClaims() {
    this.setState({ isLoading: true });
    this.props.resetClaims()
      .then(() => {
        this.props.history.push('/'); // might need saved previous path
      });
  }

  render() {
    return (
      <>
        <h2>Congratulations!</h2>
        <p>You have successfully registered for two factor authentication</p>
        <p>When you are later prompted to use 2FA you will be required to enter a security code, or an authenticator app generated security code.</p>
        <div className={styles.buttons}>
          <LoadingOverlay show={this.state.isLoading} width='100%'>
            <Button
              variant='contained'
              onClick={() => this.resetClaims()}
              fullWidth
            >
              Done
            </Button>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

export default withRouter(connect(null, {
  resetClaims
})(Confirmation));