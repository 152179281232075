import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { phoneLastFour } from 'utils/helpers/sanitation';

import SubmitToken from '../../SubmitToken';

import {
  Button,
} from '@mui/material';

import {
  LoadingOverlay,
} from '@frontend/common';

import {
  getTwoFactorToken,
} from '../../actions';

import { REGISTER_METHODS } from '../../constants.js';

import styles from '../../styles.module.css';

export class VerifySmsOrCall extends Component {

  static propTypes = {
    onVerificationSuccess: PropTypes.func.isRequired,
    onVerificationFail: PropTypes.func.isRequired,
    method: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
  };

  state = {
    isSubmitting: false,
    isResubmitting: false,
    attemptWarning: '',
  };

  resubmitPhoneCode = () => {
    const { method, getTwoFactorToken } = this.props;
    this.setState({ isResubmitting: true });
    getTwoFactorToken(method);
    setTimeout(() => {
      this.setState({ isResubmitting: false });
    }, 5000);
  }

  render() {
    const { method, phoneNumber, onVerificationSuccess, onVerificationFail } = this.props;
    const { isSubmitting, isResubmitting } = this.state;

    const lastFour = phoneLastFour(phoneNumber);
    let resubmitBtn, sendConfirmMsg;
    switch (method) {
      case REGISTER_METHODS.CALL:
        resubmitBtn = isResubmitting ? 'calling...' : 'call me again';
        sendConfirmMsg = `We called (***) ***-${lastFour} with a security code`;
        break;
      case REGISTER_METHODS.SMS:
        resubmitBtn = isResubmitting ? 'sending...' : 'resend code';
        sendConfirmMsg = `We sent a security code to (***) ***-${lastFour}`;
        break;
      default: break;
    }

    return (
      <>
        <h3>Security Code</h3>
        <div>{sendConfirmMsg}</div>

        <SubmitToken
          method={method}
          disabled={isResubmitting}
          getIsSubmitting={isSubmitting => this.setState({ isSubmitting })}
          onVerificationSuccess={onVerificationSuccess}
          onVerificationFail={onVerificationFail}
        />

        <div className={styles.buttons}>
          <LoadingOverlay show={isResubmitting} width='100%'>
            <Button
              color='secondary'
              variant='text'
              style={{ marginBottom: '10px' }}
              type='submit'
              disabled={isSubmitting}
              onClick={this.resubmitPhoneCode}
              fullWidth
            >
              {resubmitBtn}
            </Button>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

export default connect(null, {
  getTwoFactorToken,
})(VerifySmsOrCall);