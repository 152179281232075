import { cloneDeep } from 'lodash';

import {
  TWO_FACTOR_PREFERRED_METHOD,
  TWO_FACTOR_SAVE_PREFERRED_METHOD,
  VERIFY_PIN
} from './constants';

const initialState = {
  preferredInfo: {
    CountryCode: '+1',
    PhoneNumber: '',
    AlwaysRequired: false, // assuming user has no saying in their user preferences
    PreferredMethod: '',
  },
  PINVerification: { IdentityVerified: false },
};

function MultifactorReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case TWO_FACTOR_PREFERRED_METHOD:
    case TWO_FACTOR_SAVE_PREFERRED_METHOD: {
      newState.preferredInfo = action.payload.data;
      return newState;
    }

    case VERIFY_PIN: {
      newState.PINVerification = action.payload.data;
      return newState;
    }

    default:
      return state;
  }
}

export default MultifactorReducer;
