import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QRCode from 'react-qr-code';

import SubmitToken from '../../SubmitToken';

import {
  LoadingOverlay,
} from '@frontend/common';

import {
  getInitialTwoFactorQRCode,
  getTwoFactorQRCode,
} from '../../actions';

import { REGISTER_METHODS } from '../../constants';

import { TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';

import styles from '../../styles.module.css';

export class RegisterAuthenticator extends Component {

  static propTypes = {
    getInitialTwoFactorQRCode: PropTypes.func.isRequired,
    getTwoFactorQRCode: PropTypes.func.isRequired,
    onVerificationSuccess: PropTypes.func.isRequired,
    onVerificationFail: PropTypes.func,
    registerType: PropTypes.string,
  };

  state = {
    isLoading: false,
    QRCodeInfo: null
  };

  handleInput = name => e => {
    if (this.state.token) {
      this.setState({
        token: false,
        tokenError: '',
        [name]: e.target.value
      });
    }
    else {
      this.setState({ [name]: e.target.value });
    }
  }

  componentDidMount() {
    const { getInitialTwoFactorQRCode, getTwoFactorQRCode, registerType } = this.props;
    // If initial 2FA registration, the endpoint does not need to validate security code. Update is done on MyInfo page and requires security code validation.
    const getQRCode = registerType === TWO_FACTOR_TYPES.UPDATE ? getTwoFactorQRCode : getInitialTwoFactorQRCode;

    this.setState({ isLoading: true });
    getQRCode()
      .then((response) => {
        const { ImageUrl, Code } = response.payload.data;
        this.setState({ isLoading: false, QRCodeInfo: { ImageUrl, Code } });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { onVerificationSuccess, onVerificationFail } = this.props;
    const { isLoading, QRCodeInfo } = this.state;
    return (
      <div style={{ textAlign: 'center' }}>
        <LoadingOverlay show={isLoading}>
          <h2>Get code from an authenticator app</h2>

          <div className={styles.intructionSteps_container}>
            <div className={styles.intructionSteps_body}>Step 1: Download authenticator app</div>
            <div className={styles.intructionSteps_body} style={{ marginTop: '15px' }}>Step 2: Scan the QR image or enter the code below the image into your authenticator app</div>
          </div>

          <div className={styles.QRCode} style={{ marginTop: '15px' }}>
            {QRCodeInfo && <QRCode value={QRCodeInfo.ImageUrl} />}
            <br /><br />
            {QRCodeInfo && QRCodeInfo.Code}
          </div>

          <div className={styles.intructionSteps_container}>
            <div className={styles.intructionSteps_body} style={{ marginTop: '15px', marginBottom: '15px' }}>Step 3: Enter the my529 security code you see in your authenticator app</div>
          </div>
        </LoadingOverlay>

        <SubmitToken
          method={REGISTER_METHODS.INAPPTOKEN}
          disabled={isLoading}
          onVerificationSuccess={onVerificationSuccess}
          onVerificationFail={onVerificationFail}
        />

      </div>
    );
  }
}

export default connect(null, {
  getInitialTwoFactorQRCode,
  getTwoFactorQRCode,
})(RegisterAuthenticator);